import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { useServer } from "../../contexts/Server";
import { useParams } from "react-router-dom";
import Screen from "../Screen/Screen";

const Main = () => {
    const { data, socket } = useServer();
    const { state } = useParams();
    const [index, setIndex] = useState(state || socket.data);
    const [details, setDetails] = useState(data.data[index]);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        let targetIndex = state || socket.data;

        if (index !== targetIndex) {
            setIsAnimating(true);

            const timer = setTimeout(() => {
                setIndex(targetIndex);
                setDetails(data.data[targetIndex]);
                setIsAnimating(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [state, socket.data, index, data.data]);

    if (state && state > socket.data) {
        return <>Мы еще не дошли до этого!</>;
    }

    return (
        <Screen screen={details} key={index} isAnimating={isAnimating} />
    );
};

export default Main;
