import React, { createContext, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import useCustomFetch from "../hooks/useCustomFetch";

const ServerContext = createContext();

export const ServerProvider = ({ children }) => {
    const [socketData, setSocketData] = useState({ data: null, error: null, loading: true });

    const { data, error, loading } = useCustomFetch(`${process.env.REACT_APP_BASE_URL}/states`);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASE_URL);

        setSocketData(prevState => ({ ...prevState, loading: true }));

        socket.on('connect', () => {
            setSocketData(prevState => ({ ...prevState, loading: false }));
        });

        socket.on('stateChanged', (data) => {
            setSocketData({ data: data, error: null, loading: false });
        });
        socket.on('currentState', (data) => {
            setSocketData({ data: data, error: null, loading: false });
        });

        socket.on('error', (err) => {
            setSocketData({ data: null, error: err.message, loading: false });
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <ServerContext.Provider value={{ data: { data, error, loading }, socket: socketData }}>
            {children}
        </ServerContext.Provider>
    );
};

export const useServer = () => useContext(ServerContext);
