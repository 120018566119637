import React from 'react';
import styles from './index.module.css';
import {Link as RouterLink} from 'react-router-dom';
import {usePreferences} from "../../../contexts/Preferences";

const Link = ({link}) => {
    const {language} = usePreferences();

    return (
        <RouterLink to={link.url} className={styles.container} target={'_blank'}>
            <img src={link.icon_url} alt="icon"/>
            <span>{link[`text_${language}`]}</span>
        </RouterLink>
    );
}

export default Link;
