import React from 'react';
import styles from './index.module.css';

const Technology = ({technology}) => {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <span className={styles.lang}>{technology.language}</span>
                <span className={styles.knowledge}>{technology.knowledge}/10</span>
            </div>
            <span className={styles.name}>{technology.name}</span>
        </div>
    );
}

export default Technology;
