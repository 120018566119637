import Router from "./utils/Router";
import {useServer} from "./contexts/Server";
import History from "./components/History/History";

const App = () => {
    const {data, socket} = useServer();
    if (data.loading || socket.loading || !data.data || !socket) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Router />
            <History />
        </>
    );
}

export default App;
