import React, { createContext, useContext, useState, useEffect } from 'react';

const PreferencesContext = createContext();

const getOrSetFromLocalStorage = (key, value) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
        return JSON.parse(storedValue);
    } else {
        localStorage.setItem(key, JSON.stringify(value));
        return value;
    }
};

export const PreferencesProvider = ({ children }) => {
    // const [language, setLanguage] = useState(() =>
    //     getOrSetFromLocalStorage('language', navigator.language.includes('ru') ? 'ru' : 'eng')
    // );
    const [language, setLanguage] = useState("en");
    const [theme, setTheme] = useState(() =>
        getOrSetFromLocalStorage('theme', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
    );
    const [reducedMotion, setReducedMotion] = useState(() =>
        getOrSetFromLocalStorage('reducedMotion', window.matchMedia('(prefers-reduced-motion: reduce)').matches)
    );

    const changeLanguage = (newLanguage) => {
        const validLanguage = newLanguage === 'ru' || newLanguage === 'eng' ? newLanguage : 'eng';
        setLanguage(validLanguage);
        localStorage.setItem('language', JSON.stringify(validLanguage));
    };

    const changeReducedMotion = (newReducedMotion) => {
        const validReducedMotion = Boolean(newReducedMotion);
        setReducedMotion(validReducedMotion);
        localStorage.setItem('reducedMotion', JSON.stringify(validReducedMotion));
    };

    const changeTheme = (newTheme) => {
        const validTheme = newTheme === 'light' || newTheme === 'dark' ? newTheme : 'dark';
        setTheme(validTheme);
        localStorage.setItem('theme', JSON.stringify(validTheme));
    };

    return (
        <PreferencesContext.Provider
            value={{
                language,
                reducedMotion,
                theme,
                changeLanguage,
                changeReducedMotion,
                changeTheme,
            }}
        >
            {children}
        </PreferencesContext.Provider>
    );
};

// Custom hook to access preferences context
export const usePreferences = () => useContext(PreferencesContext);
