import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {PreferencesProvider} from "./contexts/Preferences";
import {ServerProvider} from "./contexts/Server";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PreferencesProvider>
            <ServerProvider>
                <App/>
            </ServerProvider>
        </PreferencesProvider>
    </BrowserRouter>
);
