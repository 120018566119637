import React from 'react';
import styles from './index.module.css';
import {useServer} from "../../contexts/Server";
import {Link, NavLink} from "react-router-dom";
import {usePreferences} from "../../contexts/Preferences";

const History = () => {
    const {data, socket} = useServer();
    const {language} = usePreferences();
    return (
        <div className={styles.container}>
            <span className={styles.headline}>History</span>
            <div className={styles.list}>
                {data.data.map((item, index) => {
                    if (socket.data >= index) {
                        return (<Link to={`/state/${index}`} className={`${styles.link} ${socket.data === index && `${styles.active}`}`}>{item[`headline_${language}`]}</Link>)
                    } else {
                        return null;
                    }
                })}
                <NavLink to={'/'} className={`${styles.link} ${styles.right}`}>Current Slide</NavLink>
            </div>
        </div>
    );
}

export default History;
