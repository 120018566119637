import React from 'react';
import styles from './index.module.css';
import {usePreferences} from "../../../contexts/Preferences";

const Experience = ({experience}) => {
    const {language} = usePreferences();
    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        })
    }
    return (
        <div className={styles.container}>
            <span className={styles.name}>{experience[`role`]}</span>
            <div className={styles.company}>
                <span>{experience[`company_name`]}</span>
                <div className={styles.date}>
                    <span>{formatDate(experience[`start_date`])} - {formatDate(experience[`end_date`])}</span>
                </div>
            </div>
            <div className={styles.stackList}>
                {experience[`stack`].map(s => <span className={styles.tech}>{s}</span>)}
            </div>
            <span className={styles.description}>{experience[`company_description_${language}`]}</span>
        </div>
    );
}

export default Experience;
