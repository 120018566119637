import React from 'react';
import styles from './index.module.css';
import {usePreferences} from "../../contexts/Preferences";
import Link from "./Link/Link";
import Experience from "./Experience/Experience";
import Technology from "./Technology/Technology";
import {useServer} from "../../contexts/Server";

const Screen = ({screen, isAnimating}) => {
    const {language} = usePreferences();
    const {socket} = useServer()

    return (
        <div className={`${styles.container} ${isAnimating ? styles.fadeOut : ''}`}>
            <span className={styles.headline}>{screen[`headline_${language}`]} <span className={styles.fade}>- 0{socket.data + 1}</span></span>
            <span className={styles.description}>{screen[`description_${language}`]}</span>
            <div className={styles.attachments}>
                {screen.attachments.map((attachment, key) => {
                    if (!attachment) return null;
                    if (attachment.type === "link" && attachment.url) {
                        return <Link link={attachment} key={key} />;
                    } else if (attachment.type === "experience") {
                        return <Experience experience={attachment.data} key={key} />;
                    } else if (attachment.type === "technology") {
                        return <Technology technology={attachment.data} key={key} />;
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default Screen;